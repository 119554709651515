<template>
  <div class="woshuo">

    <div class="main mainWidth">
      <!-- 首页>社科视频>报刊视界 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item to="/">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/bksj">报刊视界</el-breadcrumb-item>
            <el-breadcrumb-item>我说</el-breadcrumb-item>
          </el-breadcrumb>

          <!-- 标题 -->

          <!-- <div class="woshuoHeadline">
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div class="title">我说</div>
                <div class="line">
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
              </el-col>
            </el-row>
          </div> -->
          <ListHeadline :title="'我说'" />

          <!-- 列表 -->
          <div class="page_k">
            <!-- 单列表 -->
            <div class="soooooo">
              <el-row type="flex" justify="center" v-for="a in list" :key="a.id">
                <el-col :span="24">
                  <router-link target="_blank"
                    :to="{ path: '/xq', query: { qing: a.id, mian: 'navwo' } }">

                    <!-- aaa -->
                    <div class="page_top">
                      <el-row :gutter="$store.state.isEquipment == 'pc' ? 22 : 0">
                        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                          <div class="page_top_img">
                            <img v-lazy="a.pub_cover" alt="" />
                          </div>
                        </el-col>
                        <el-col :xs="24" :sm="17" :md="17" :lg="17" :xl="17">
                          <div class="shu_zi">
                            <p class="left_zi">{{ a.SYS_TOPIC }}</p>
                            <div class="zhong_zi">
                              <span>{{
                                $AwayTell(a.DESCRIPTION, 0, $store.state.isSmallPc ? 160 : 230)
                              }}</span>
                              <span class="particulars">[详情]</span>
                            </div>

                            <p class="right_zi">
                              {{ $ModuleDate(a.RECORD_TIME) }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </router-link>

                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 分页 -->
          <Paging :value="value" @transfer="getData" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ListHeadline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  components: {
    Paging,
    ListHeadline
  },
  props: {},
  data() {
    return {
      list: [],
      value: {
        path: '59',
        amount: '9'
      }
    };
  },
  watch: {},
  created() {
    // console.log('woshuo',this.list);
  },
  methods: {
    getData(msg) {
      this.list = msg;
      // console.log('mmmmsg',msg);
    },
  },
};
</script>
<style lang='scss'>
.woshuo {
  background: #f6f6f6;
  // padding-bottom: 2.5rem;

  // 标题
  // .woshuoHeadline {
  //   margin-top: 0.875rem;

  //   .title {
  //     font-size: 1.875rem;
  //     font-weight: bold;
  //     color: #212121;
  //     line-height: 2.8125rem;
  //     padding-left: .3125rem;
  //   }

  //   .line {
  //     .left {
  //       height: 0rem;
  //       width: 4rem;
  //       border-bottom: .3125rem solid #2181b0;
  //       border-right: .3125rem solid transparent;
  //       border-left: .3125rem solid #2181b0;
  //       display: inline-block;
  //     }

  //     .right {
  //       width: calc(100% - 6rem);
  //       border-bottom: 1px solid #2181b0;
  //       display: inline-block;
  //       margin-left: 0.5rem;
  //     }
  //   }
  // }
  .main {

    // .maysk {
    //   font-size: 1.875rem;
    //   font-family: Microsoft YaHei;
    //   font-weight: bold;
    //   color: #212121;
    //   margin-bottom: 1.25rem;
    // }

    // .line {
    //   .line_l {
    //     width: 4.5rem;
    //     height: .3125rem;
    //     background: linear-gradient(237deg, transparent 5px, #2181b0 0) top right;
    //   }

    //   .line_r {
    //     width: 82.5rem;
    //     height: .0625rem;
    //     background: #668594;
    //     margin-left: .1875rem;
    //   }
    // }

    .page_k {
      .page_top {
        background: #ffffff;
        margin-top: 1rem;
        padding: 1.25rem;
        position: relative;

        .page_top_img {
          // height: 11rem;
          // height: 12.4375rem;

          img {
            width: 100%;
            aspect-ratio: 4/3;
            display: block;
            height: 243px;
          }

          @media screen and(max-width:1440px) {
            img {
              height: 169px;
            }
          }
        }

        .shu_zi {
          color: #212121;
          height: 100%;

          .left_zi {
            font-size: 1.25rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .zhong_zi {
            margin-top: 1rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 1.875rem;
          }

          .particulars {
            color: #287498;
            font-size: 1rem;
            cursor: pointer;
            margin-left: .3125rem;
          }

          .right_zi {
            position: absolute;
            bottom: 0%;
            color: #c29460;
          }
        }
      }
    }
  }
}
</style>