var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getData),expression:"getData"}],staticClass:"woshuo_phone",attrs:{"infinite-scroll-distance":"10","infinite-scroll-disabled":"busy"}},[_c('div',{staticClass:"main"},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',[_c('el-breadcrumb-item',{attrs:{"to":"/"}},[_vm._v("社科视频首页")]),_c('el-breadcrumb-item',{attrs:{"to":"/bksj"}},[_vm._v("报刊视界")]),_c('el-breadcrumb-item',[_vm._v("我说")])],1),_c('ListHeadline',{attrs:{"title":'我说'}})],1)],1),_c('div',{staticClass:"page_k"},[_c('div',{staticClass:"soooooo"},_vm._l((_vm.list),function(a){return _c('el-row',{key:a.id,attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":24}},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/xq',
                query: {
                  qing: a.id,
                  mian: 'navwo',
                },
              }}},[_c('div',{staticClass:"page_top"},[_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 22 : 0}},[_c('el-col',{attrs:{"xs":24,"sm":7,"md":7,"lg":7,"xl":7}},[_c('div',{staticClass:"page_top_img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(a.pub_cover),expression:"a.pub_cover"}],attrs:{"alt":""}})])]),_c('el-col',{attrs:{"xs":24,"sm":16,"md":16,"lg":16,"xl":16}},[_c('div',{staticClass:"shu_zi"},[_c('p',{staticClass:"left_zi"},[_vm._v(_vm._s(a.SYS_TOPIC))]),_c('div',{staticClass:"zhong_zi"},[_c('span',[_vm._v(" "+_vm._s(_vm.$AwayTell(a.DESCRIPTION, 0, 80))+" ")]),_c('span',{staticClass:"particulars"},[_vm._v("[详情]")])]),_c('p',{staticClass:"right_zi"},[_vm._v(" "+_vm._s(_vm.$ModuleDate(a.RECORD_TIME))+" ")])])])],1)],1)])],1)],1)}),1)]),_c('div',{staticClass:"loading"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticClass:"busy"},[_vm._v("加载中...")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.finish),expression:"finish"}],staticClass:"finish"},[_vm._v("没有更多了")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }