<template>
  <div
    class="woshuo_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy"
  >
    <div class="main">
      <!-- 首页>社科视频>报刊视界 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb>
            <el-breadcrumb-item to="/">社科视频首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/bksj">报刊视界</el-breadcrumb-item>
            <el-breadcrumb-item>我说</el-breadcrumb-item>
          </el-breadcrumb>

          <!-- 标题 -->
          <ListHeadline :title="'我说'" />
        </el-col>
      </el-row>
      <!-- 列表 -->
      <div class="page_k">
        <!-- 单列表 -->
        <div class="soooooo">
          <el-row type="flex" justify="center" v-for="a in list" :key="a.id">
            <el-col :span="24">
              <router-link
                target="_blank"
                :to="{
                  path: '/xq',
                  query: {
                    qing: a.id,
                    mian: 'navwo',
                  },
                }"
              >
                <!-- aaa -->
                <div class="page_top">
                  <el-row :gutter="$store.state.isEquipment == 'pc' ? 22 : 0">
                    <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
                      <div class="page_top_img">
                        <img  v-lazy="a.pub_cover" alt="" />
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
                      <div class="shu_zi">
                        <p class="left_zi">{{ a.SYS_TOPIC }}</p>
                        <div class="zhong_zi">
                          <span>
                            {{ $AwayTell(a.DESCRIPTION, 0, 80) }}
                          </span>
                          <span class="particulars">[详情]</span>
                        </div>

                        <p class="right_zi">
                          {{ $ModuleDate(a.RECORD_TIME) }}
                        </p>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="loading">
        <p class="busy" v-show="busy">加载中...</p>
        <p class="finish" v-show="finish">没有更多了</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import ListHeadline from "@/components/ListHeadline.vue";

export default {
  components: {
    ListHeadline,
  },
  data() {
    return {
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
    };
  },
  watch: {
    $route: {
      handler() {
        this.load();
      },
    },
  },
  methods: {
    load() {
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData() {
      if(this.finish){
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 59,
          pageNo: this.pageNo,
          pageSize: 9,
        })
        .then((res) => {
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
  },
};
</script>
<style lang='scss'>
.woshuo_phone {
  background: #f6f6f6;
  padding:0 0.5rem;

  .kong {
    width: 100%;
    width: 3.8125rem;
  }

  // 标题
  .main {
    .page_k {
      .page_top {
        background: #ffffff;
        margin-top: 1rem;
        padding: 1.25rem;
        position: relative;

        .page_top_img {
          // height: 11rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .shu_zi {
          color: #212121;
          height: 100%;
          .left_zi {
            font-size: 1.25rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 1rem;
          }

          .zhong_zi {
            margin-top: 0.75rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 1.875rem;

            .particulars {
              color: #287498;
              font-size: 1rem;
              cursor: pointer;
              margin-left: 0.3125rem;
            }
          }

          .right_zi {
            color: #c29460;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .loading {
    margin: 0.5rem 0;

    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
  .crumbs {
    padding: 1rem 0;
  }
}
</style>